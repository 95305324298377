<template>
  <main id="content" role="main" class="overflow-hidden">
    <!-- ========== Hero Section ========== -->
    <div class="position-relative z-index-2 bg-img-hero overflow-hidden" :style="{'background-image': 'url(' + require('@/assets/svg/components/hk-bg-light-2.jpg') + ')'}">
      <!-- Breadcrumb -->
      <div class="container space-top-2 mt-md-6 pl-0">
        <div class="row justify-content-sm-between mb-5">
          <div class="col-md">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-md-3 mb-0">
                <li class="breadcrumb-item active">
                  <!-- <a href="/events"> -->
                  <router-link :to="{ path: '/events' }">
                    Events
                  </router-link>
                <!-- </a> -->
                </li>
                <li class="breadcrumb-item active" aria-current="page">Apidays Hong Kong 2023</li>
              </ol>
            </nav>
          </div>
          <!-- <div class="col-md-auto text-md-right mt-2 ml-3 justify-content-end">
            <a class="btn btn-soft-primary btn-xs" href="/events/apidays-live-hongkong-tc">切換繁體中文 <i class="fas fa-exchange-alt" /></a>
          </div> -->
        </div>
      </div>
      <!-- Title -->
      <div class="container space-bottom-2 position-relative">
        <div class="w-100 w-md-75 w-lg-65 mx-auto text-center">
          <span class="d-block small font-weight-bold text-cap mb-2">AUGUST 30 & 31, 2023</span>
          <h1>Apidays Hong Kong 2023</h1>
          <!-- <h3>API-First Digital Transformation &amp; Platform Economy</h3> -->
          <!-- <img class="img-fluid mt-5" src="@/assets/img/events/apidays-live-hongkong/2022/images/apidays-hongkong-2022-logo.png" style="max-width:15rem"> -->
          <p class="lead">The API Landscape of Hong Kong</p>
        </div>
        <!-- <div class="text-center pt-9">
          <a data-gtm-att="" class="btn btn-primary transition-3d-hover mb-3 mb-sm-0 mx-2" href="https://hopin.com/events/apidays-hong-kong-2022" target="_blank">Register Now</a>
          <a id="" class="btn btn-outline-primary mb-3 mb-sm-0 mx-2" href="/contact">Contact Us</a>
        </div> -->
        <!-- <div class="w-lg-75 mx-lg-auto pt-9">
          <div class="card">
            <div class="card-body p-2">
              <div id="VideoPlayer" class="video-player" style="background-color:#f7faff">
                <img class="img-fluid video-player-preview" src="@/assets/img/events/apidays-live-hongkong/2022/images/apidays-hongkong-2022-video-cover.jpg">
                <a
                  class="js-inline-video-player video-player-btn video-player-centered"
                  href="javascript:;"
                  data-hs-video-player-options="{
                    &quot;videoType&quot;: &quot;vimeo&quot;,
                    &quot;videoId&quot;: &quot;747586060&quot;,
                    &quot;parentSelector&quot;: &quot;#VideoPlayer&quot;,
                    &quot;targetSelector&quot;: &quot;#VideoIframe&quot;,
                    &quot;isAutoplay&quot;: true,
                    &quot;isMuted&quot;: false,
                    &quot;classMap&quot;: {
                    &quot;toggle&quot;: &quot;video-player-played&quot;
                    }
                    }"
                >
                  <span id="ga-event-apidays-2022-opening-video-play-button" class="video-player-icon video-player-icon-primary">
                    <i class="fas fa-play" />
                  </span>
                </a>
                <div class="embed-responsive embed-responsive-16by9">
                  <div id="VideoIframe" />
                </div>
              </div>
            </div>
          </div>
          <p class="text-center font-size-1 mt-2">Apidays Hong Kong 2022—Welcome and Opening Remarks By Patrick Fan</p>
        </div> -->

        <div class="text-center mt-6">
          <a href="https://www.apidays.hk/2023" class="btn btn-primary btn-wide" target="_blank">
            Visit Official Website <i class="fas fa-external-link-alt ml-1" />
          </a>
        </div>
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== Organiser Section ========== -->
    <OrganiserBar :list="organiser_list" />
    <!-- ========== End Organiser Section ========== -->

    <!-- ========== Participants Section ========== -->
    <div class="space-2" style="background-color:#f5f9fc;">
      <div class="container">
        <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-9">
          <span class="d-block small font-weight-bold text-cap mb-2">Participants</span>
          <h2>In 2022, 1000+ Participants from</h2>
        </div>
        <div class="mt-5 text-center">
          <a
            class="js-fancybox media-viewer"
            data-hs-fancybox-options="{
                    &quot;speed&quot;: 700
                }"
            :data-src="require('@/assets/img/events/apidays-live-hongkong/2022/images/apidays-hongkong-2022-participants.jpg')"
            data-caption="Apidays Hong Kong 2022 Participants"
            style="cursor: pointer;"
          >
            <img class="img-fluid rounded shadow" src="@/assets/img/events/apidays-live-hongkong/2022/images/apidays-hongkong-2022-participants.jpg" alt="Apidays Hong Kong 2022 Participants Logo Wall" data-aos="fade-up">
          </a>
        </div>
      </div>
    </div>
    <!-- ========== End Participants Section ========== -->

    <!-- ========== Speakers Section ========== -->
    <div class="space-2" style="background-color:#f5f9fc;">
      <div class="container">
        <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-5 mb-md-9">
          <span class="d-block small font-weight-bold text-cap mb-2">Our Speakers</span>
          <h2>2022 Speaker Lineup Reference</h2>
        </div>

        <!-- Filters -->
        <!-- <input v-for="list in speaker_list" :id="list.group" :key="list.group" v-model="selected_group" type="radio" name="categories" :value="list.group">

        <ol class="filters-btn" style="margin-left: 1rem; overflow: auto; white-space: nowrap; -webkit-overflow-scrolling: touch;">
          <li v-for="list in speaker_list" :key="list.group">
            <label class="btn btn-sm btn-soft-primary btn-pill py-2 px-3 m-1" :class="{'selectedGroup': selected_group === list.group}" :for="list.group">{{ list.title }}</label>
          </li>
        </ol> -->
        <!-- End Filters -->

        <span v-for="list in speaker_list" :key="`${list.group}_speaker`">
          <ol v-if="list.group === selected_group" class="filters-container row">
            <!-- Speaker -->
            <li v-for="speaker in list.speaker" :key="speaker.name" class="col-12 col-sm-6 col-lg-3 px-2 mb-3 filters-item" :data-category="list.group">
              <a class="card h-100 transition-3d-hover" data-toggle="modal" :data-target="'#' + speaker.data_target" style="cursor:pointer">
                <div class="card-body">
                  <div class="avatar avatar-xl avatar-circle mb-4" style="border: 2px solid #f4f5fa">
                    <img class="avatar-img" :src="require('@/assets/img/events/apidays-live-hongkong/2022/speakers/' + speaker.image_url)">
                  </div>
                  <h3>{{ speaker.name }}</h3>
                  <p>{{ speaker.job_title }}</p>
                </div>
              </a>
              <!-- Modal -->
              <div :id="speaker.data_target" class="modal fade" tabindex="-1" role="dialog" :aria-labelledby="speaker.data_target + 'Title'" aria-hidden="true">
                <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <!-- Header -->
                    <div class="modal-top-cover bg-primary text-center">
                      <figure class="position-absolute right-0 bottom-0 left-0">
                        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                          <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                        </svg>
                      </figure>

                      <div class="modal-close">
                        <button type="button" class="btn btn-icon btn-sm btn-ghost-light" data-dismiss="modal" aria-label="Close">
                          <svg width="16" height="16" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                          </svg>
                        </button>
                      </div>

                      <figure class="w-100 position-absolute bottom-0 left-0">
                        <img class="img-fluid" src="@/assets/svg/components/isometric-lego.svg">
                      </figure>
                    </div>
                    <!-- End Header -->

                    <!-- Speaker Profile  -->
                    <div class="modal-top-cover-avatar">
                      <img class="avatar avatar-xl avatar-circle avatar-border-lg avatar-centered shadow-soft" :src="require('@/assets/img/events/apidays-live-hongkong/2022/speakers/' + speaker.image_url)">
                    </div>
                    <div class="text-center m-3 mx-5">
                      <h2 class="mb-0">{{ speaker.name }}</h2>
                      <p v-if="speaker.company" class="d-block font-weight-bold mb-1">{{ speaker.company }}</p>
                      <p>{{ speaker.position }}</p>
                      <!-- <a :href="require('@/assets/img/events/apidays-live-hongkong/speakers/' + speaker.media_card)" class="btn btn-xs btn-soft-secondary" download>
                        <i class="fas fa-download mr-1" /> Download Social Media Card
                      </a> -->
                    </div>
                    <!-- End Speaker Profile  -->

                    <!-- Body -->
                    <div class="modal-body pb-sm-5 px-sm-5">
                      <div v-if="speaker.about" class="row mb-6">
                        <div class="col-12 col-lg-3 mb-1">
                          <h5 class="text-cap" style="color:#999999">about</h5>
                        </div>
                        <div class="col-12 col-lg-9">
                          <p v-for="item in speaker.about" :key="item">
                            {{ item }}
                          </p>
                          <div v-if="speaker.about_special" v-html="speaker.about_special" />
                        </div>
                      </div>

                      <div v-if="speaker.topic_title" class="row mb-6">
                        <div class="col-12 col-lg-3 mb-1">
                          <h5 class="text-cap" style="color:#999999">speaking topic</h5>
                        </div>
                        <div class="col-12 col-lg-9">
                          <h3 class="mb-2">{{ speaker.topic_title }}</h3>
                          <span v-if="speaker.topic_description">
                            <p v-for="item in speaker.topic_description" :key="item">
                              {{ item }}
                            </p>
                          </span>
                          <div v-if="speaker.topic_special" v-html="speaker.topic_special" />
                          <div v-if="speaker.topic_title_2" class="mt-6">
                            <h3 class="mb-2">{{ speaker.topic_title_2 }}</h3>
                            <span v-if="speaker.topic_description_2">
                              <p v-for="item in speaker.topic_description_2" :key="item">
                                {{ item }}
                              </p>
                            </span>
                            <div v-if="speaker.topic_special_2" v-html="speaker.topic_special_2" />
                          </div>
                        </div>
                      </div>

                      <div v-if="(speaker.linkedin || speaker.twitter)" class="row mb-6">
                        <div class="col-12 col-lg-3 mb-1">
                          <h5 class="text-cap" style="color:#999999">get connected</h5>
                        </div>
                        <div class="col-12 col-lg-9">
                          <ul class="list-inline mb-0">
                            <li v-if="speaker.linkedin" class="list-inline-item">
                              <a class="btn btn-sm btn-icon btn-soft-secondary rounded" :href="'https://www.linkedin.com/in/' + speaker.linkedin + '/'" target="_blank">
                                <i class="fab fa-linkedin" />
                              </a>
                            </li>
                            <li v-if="speaker.twitter" class="list-inline-item">
                              <a class="btn btn-sm btn-icon btn-soft-secondary rounded" :href="'https://twitter.com/' + speaker.twitter" target="_blank">
                                <i class="fab fa-twitter" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="text-center mt-9">
                        <button type="button" class="btn btn-wide btn-primary transition-3d-hover" data-dismiss="modal" aria-label="Close">Back to Speaker List</button>
                      </div>
                    </div>
                    <!-- End Body -->
                  </div>
                </div>
                <!-- End Modal -->
              </div>
            </li>
          <!-- End Speaker -->
          </ol>
        </span>
      </div>
    </div>
    <!-- ========== End Speakers Section ========== -->

    <!-- ========== Gallery Section ========== -->
    <!-- <div class="space-2">
      <div class="position-relative">
        <div class="container space-1 position-relative z-index-2 ">
          <div class="w-md-80 w-lg-80 text-center mx-md-auto mb-9">
            <span class="d-block small font-weight-bold text-cap mb-2">Gallery</span>
            <h2>The Best Moments of Apidays Live Hong Kong 2022</h2>
          </div>
          <div id="fancyboxGallery1" class="row">
            <div v-for="item in gallery_list" :key="item.image_url" class="col-12 px-2 transition-3d-hover" :class="item.class">
              <a
                class="js-fancybox media-viewer"
                href="javascript:;"
                data-hs-fancybox-options="{
                            &quot;selector&quot;: &quot;#fancyboxGallery1 .js-fancybox&quot;,
                            &quot;speed&quot;: 700,
                            &quot;loop&quot;: true
                        }"
                :data-src="require('@/assets/img/events/apidays-live-hongkong/2022/images/' + item.image_url)"
                data-caption="Apidays Live Hong Kong 2022"
              >
                <div class="img-fluid rounded h-250rem bg-img-hero shadow-soft" :style="{'background-image': 'url(' + require('@/assets/img/events/apidays-live-hongkong/2022/images/' + item.image_url) + ')'}" data-aos="fade-up" :data-aos-delay="item.data_aos_delay" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- ========== End Gallery Section ========== -->

    <!-- ========== Past year Section ========== -->
    <div>
      <div class="container space-2">
        <div class="w-md-80 w-lg-65 text-center mx-md-auto mb-5">
          <h2 class="mb-3">A Look Back at the Past Conferences</h2>
          <p>Thanks for all your support to Apidays Live Hong Kong 2020-2022.<br>Let's find out what happened in the past few years.</p>
        </div>

        <div class="w-lg-80 mx-auto mt-8 mb-11">
          <div class="card overflow-hidden p-5 bg-primary">
            <div class="row justify-content-md-start align-items-md-center text-center text-md-left z-index-2">
              <div class="col-md-8 col-lg-9 mb-3 mb-md-0 pl-lg-5">
                <h3 class="mb-0 text-white">Apidays 2022 playback videos are now available!</h3>
              </div>
              <div class="col-md-4 col-lg-3 text-md-right">
                <a id="" class="btn btn-light transition-3d-hover" href="javascript:;" data-toggle="modal" data-target="#oahResourcesModal">Watch Now</a>
              </div>
            </div>
            <figure class="w-35 position-absolute top-0 left-0 mt-n11 ml-n11">
              <img class="img-fluid" src="@/assets/svg/components/half-circle-2.svg">
            </figure>
          </div>
        </div>

        <div class="row mx-auto">
          <div class="col-md-4 mb-4 mb-md-0">
            <router-link class="card h-100" :to="{ path: '/events/apidays-live-hongkong-2022' }">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="@/assets/img/thumbnails/apidays-live-hongkong-2022.jpg">
                <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                  </svg>
                </figure>
              </div>
              <div class="card-body">
                <div class="mb-5">
                  <h4>Apidays Live Hong Kong 2022</h4>
                  <p class="font-size-1">API-First Digital Transformation & Platform Economy</p>
                </div>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </router-link>
          </div>
          <div class="col-md-4 mb-4 mb-md-0">
            <router-link class="card h-100" :to="{ path: '/events/apidays-live-hongkong-2021' }">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="@/assets/img/thumbnails/apidays-live-hongkong-2021.jpg">
                <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                  </svg>
                </figure>
              </div>
              <div class="card-body">
                <div class="mb-5">
                  <h4>Apidays Live Hong Kong 2021</h4>
                  <p class="font-size-1">API Ecosystem &amp; Data Interchange</p>
                </div>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </router-link>
          </div>
          <div class="col-md-4 mb-0">
            <router-link class="card h-100" :to="{ path: '/events/apidays-live-hongkong' }">
              <div class="card-img-top position-relative">
                <img class="card-img-top" src="@/assets/img/thumbnails/apidays-live-hongkong.jpg">
                <figure class="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                  </svg>
                </figure>
              </div>
              <div class="card-body">
                <div class="mb-5">
                  <h4>Apidays Live Hong Kong 2020</h4>
                  <p class="font-size-1">The Open API Economy: Finance-as-a-Service &amp; API Ecosystems</p>
                </div>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">Learn More <i class="fas fa-angle-right fa-sm ml-1" /></span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Past year Section ========== -->

    <!-- ========== Subscription Section ========== -->
    <!-- <div class="container-fluid space-2 space-top-lg-3 space-bottom-lg-2 gradient-y-sm-primary">
      <div class="row justify-content-sm-center align-items-lg-center text-center">
        <div class="col-lg-3 d-none d-lg-block" />
        <div class="col-sm-8 col-lg-6">
          <div class="mb-5">
            <h2>Interested in Apidays Live Hong Kong 2021 Event Highlights?</h2>
            <div class="w-lg-80 mx-auto">
              <p>Subscribe to our newsletter and receive exclusive replay videos &amp; presentation slides of the conference!</p>
            </div>
          </div>
          <div class="w-md-75 w-lg-80 mx-auto form-subscribe">
            <form ref="myform" />
          </div>
        </div>
        <div class="col-lg-3 d-none d-lg-block" data-aos="fade-left">
          <div class="max-w-33rem w-100 transform-rotate-2 ml-auto">
            <div class="device device-iphone-x">
              <img class="device-iphone-x-frame" src="@/assets/svg/illustrations/subscribe-mobile.svg">
            </div>

            <figure class="max-w-19rem w-100 position-absolute top-0 left-0 z-index-n1 mt-n5 ml-n5">
              <img class="img-fluid" src="@/assets/svg/components/dots-2.svg">
            </figure>
          </div>
        </div>
      </div>
    </div> -->
    <!-- ========== End Subscription Section ========== -->

    <!-- ========== Popup Modals ========== -->
    <div id="oahResourcesModal" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="oahResourcesModalTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="oahResourcesModal" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body text-center">
            <img class="img-fluid max-w-23rem mx-auto mb-3" src="@/assets/svg/illustrations/replay-video.svg">
            <p>This resource is now available on <strong>OpenAPIHub</strong>—our one-stop API Community Platform.<br>Please log in to the platform, or register as an OpenAPIHub member for FREE to watch the resource now!</p>
            <a id="" href="https://hub.openapihub.com/member/community-updates/20220824-Apidays-Hong-Kong-2022/resources" target="_blank" class="btn btn-primary transition-3d-hover mb-5">Watch on OpenAPIHub</a>
            <!-- Begin Mailchimp Signup Form -->
            <!-- <div id="mc_embed_signup">
              <form id="mc-embedded-subscribe-form" ref="mcEmbeddedSubscribeForm" action="https://openapihub.us18.list-manage.com/subscribe/post?u=b928e5178a4f8a241b088732f&amp;id=dae542b6e6&amp;f_id=00a108e7f0" method="post" name="mc-embedded-subscribe-form" class="validate" target="blank">
                <div class="text-center">
                  <h2>Subscribe to e-newsletter and watch the replay videos now!</h2>
                  <img class="img-fluid max-w-23rem mx-auto mb-3" src="@/assets/svg/illustrations/replay-video.svg">
                  <p>Sign up to our e-newsletter and we will share the resource with you via Email right away.<br>If you do not see the email in a few minutes, check your “junk mail” folder or “spam” folder.</p>
                </div>
                <div class="mc-field-group">
                  <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
                  </label>
                  <input id="mce-EMAIL" type="email" value="" name="EMAIL" class="required email" required>
                  <span id="mce-EMAIL-HELPERTEXT" class="helper_text" />
                </div>
                <div class="mb-4">
                  <p class="small">By submitting your information, you agree to receive future communications from beNovelty or OpenAPIHub by beNovelty.</p>
                </div>
                <div hidden="true"><input type="hidden" name="tags" value="2142806,2142946"></div>
                <div id="mce-responses" class="clear">
                  <div id="mce-error-response" class="response" style="display:none" />
                  <div id="mce-success-response" class="response" style="display:none" />
                </div>    // real people should not fill this in and expect good things - do not remove this or risk form bot signups
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b928e5178a4f8a241b088732f_dae542b6e6" tabindex="-1" value=""></div>
                <div class="clear"><input id="mc-embedded-subscribe" type="submit" value="Submit" name="subscribe" class="btn btn-primary border-0 m-0"></div>
              </form>
            </div> -->
            <!--End mc_embed_signup-->
          </div>
        </div>
      </div>
    </div>

    <!-- <div id="recapSubscribeForm" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="recapSubscribeFormTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="recapSubscribeForm" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="w-md-90 mx-auto mb-6 form-submit">
              <div class="text-center">
                <h4>Subscribe to our newsletter and watch playback videos NOW!</h4>
                <img class="img-fluid max-w-23rem mx-auto mb-3" src="@/assets/svg/illustrations/replay-video.svg">
              </div>
              <form ref="recapSubscribeForm" />
              <div v-if="isSubmitted" class="modal-submitted-content">
                <div class="w-lg-80 mx-auto text-center">
                  <hr>
                  <h5 class="my-5">You may find the playback videos and discover other resources of Apidays Live Hong Kong 2021 below:</h5>
                  <router-link class="card card-frame p-3" :to="{ path: '/events/apidays-live-hongkong-2021/recap' }">
                    <div class="d-flex align-items-center">
                      <figure class="max-w-6rem w-100">
                        <img class="img-fluid" src="@/assets/svg/icons/icon-41.svg">
                      </figure>
                      <div class="pl-3 font-weight-bold">Watch Apidays 2021 Recap NOW!</div>
                      <div class="ml-auto"><i class="fas fa-chevron-right" /></div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- ========== End Popup Modals ========== -->
  </main>
</template>

<script>
const $ = require('jquery')
import OrganiserBar from '@/components/organiser-bar'
import HSGoTo from '../../../assets/vendor/hs-go-to/src/js/hs-go-to'
import HSVideoPlayer from '../../../assets/vendor/hs-video-player/src/js/hs-video-player'
import '../../../assets/js/hs.core.js'
import '../../../assets/js/hs.fancybox.js'
import '../../../assets/vendor/fancybox/dist/jquery.fancybox.js'
import 'bootstrap'

export default {
  name: 'Apidays2022',
  components: {
    OrganiserBar
  },
  data() {
    return {
      isSubmitted: false,
      gallery_list: [
        {
          image_url: 'apidays-2022-snapshot-1.jpg',
          class: 'col-md-3 mb-3 mb-md-0',
          data_aos_delay: 0
        },
        {
          image_url: 'apidays-2022-snapshot-richard-1.jpg',
          class: 'col-md-5 mb-3 mb-md-0',
          data_aos_delay: 100
        },
        {
          image_url: 'apidays-2022-snapshot-trevor-1.jpg',
          class: 'col-md-4 ',
          data_aos_delay: 200
        },
        {
          image_url: 'apidays-2022-snapshot-charm-1.jpg',
          class: 'col-md-4 mb-3 mb-md-0',
          data_aos_delay: 100
        },
        {
          image_url: 'apidays-2022-snapshot-gary-1.jpg',
          class: 'col-md-5 mb-3 mb-md-0',
          data_aos_delay: 200
        },
        {
          image_url: 'apidays-2022-snapshot-2.jpg',
          class: 'col-md-3',
          data_aos_delay: 300
        }
      ],
      organiser_list: {
        title: 'Apidays Hong Kong 2023',
        image_path: 'apidays-live-hongkong',
        css: 'space-2',
        organiser: [
          {
            post: 'Lead Organizer',
            image_url: 'benovelty.png',
            data_aos_delay: 150,
            css: ''
          }
        ]
      },
      selected_group: 'Main Track',
      speaker_list: [
        {
          group: 'Main Track',
          title: 'Main Track',
          speaker: [
            {
              data_target: 'richardModal',
              image_url: 'richard-pro.jpg',
              name: 'Richard Lord',
              job_title: 'MD, Regional CIO Wholesale - ASP at HSBC',
              company: 'HSBC',
              position: 'MD, Regional CIO Wholesale - ASP',
              about: [
                'Mr. Richard Lord is a Managing Director and the Regional Chief Information Officer, Wholesale, ASP at HSBC.',
                'Mr. Lord has extensive experience working with contemporary technologies to drive innovation and change, across a variety of industries. Prior to joining HSBC, he was Deputy Global CIO for a major advertising holding company. Before that, he was an entrepreneur, having founded one of the very first internet technology companies in Australia in the early 1990s.',
                'Mr. Lord is a passionate advocate of innovation and leveraging technology to improve the experience and value provided to customers and colleagues. He has championed programs for co-creation and internal innovation and is active in engaging start-ups, fintechs and innovators across the region.',
                'Mr. Lord is an active champion for equality and meritocracy in the workplace and sits as Co-Chair of HSBC\'s Balance Employee Resources Group for gender diversity and the HSBC Male Allies in Hong Kong.'
              ],
              topic_title: 'Powering Modern Business Banking Through APIs',
              topic_description: [
                'We\'re shifting to an era of more integrated business banking, with APIs the backbone of improved connectivity, faster business and new opportunities for customer value. Hear how HSBC is innovating to open up a world of opportunity for customers.'
              ],
              linkedin: 'richardalord',
              twitter: 'richardalord'
            },
            {
              data_target: 'trevorModal',
              image_url: 'trevor-pro.jpg',
              name: 'Trevor Cheung',
              job_title: 'General Manager, Chief Digital Officer of Corporate Banking at Bank of China (Hong Kong)',
              company: 'Bank of China (Hong Kong)',
              position: 'General Manager, Chief Digital Officer of Corporate Banking',
              topic_title: 'Capturing business value with API',
              linkedin: 'trevorcheung'
            },
            {
              data_target: 'jonathanModal',
              image_url: 'jonathan-pro.jpg',
              name: 'Jonathan Cheung',
              job_title: 'Director, Open Banking & API, Digital Banking at Standard Chartered Bank (Hong Kong) Limited',
              company: 'Standard Chartered Bank (Hong Kong) Limited',
              position: 'Director, Open Banking & API, Digital Banking',
              about: [
                'Jonathan Cheung is the Director of Open Banking & API, Digital Banking from Standard Chartered Bank (Hong Kong) Limited. His role involves spread heading the open banking development for retail banking business in Hong Kong, ranging from Open API product development, ecosystem partnership collaboration, regulatory engagement to product development to facilitate business growth and digital transformation. Prior to joining Standard Chartered, Jonathan had served various business development roles at TransUnion, IBM, Microsoft and a local family office. He acquired both his master and bachelor degrees from the Hong Kong University of Science and Technology.'
              ],
              topic_title: 'Open Banking Ecosystem – Development, Opportunities & Challenges',
              linkedin: 'jonathan-cheung-854b91156'
            },
            {
              data_target: 'garyModal',
              image_url: 'gary-pro.jpg',
              name: 'Gary Ho',
              job_title: 'Chief Technology Officer of AXA HK and Macau',
              company: 'AXA HK and Macau',
              position: 'Chief Technology Officer',
              about: [
                'Worldwide digital technology executive with 20 years\' experience leading multiple successful digital innovation and execution with large multi-disciplinary and multi-cultural teams in global corporations. Mr. Ho currently works in AXA Hong Kong & Macau as the Chief Technology Officer with full responsibility for IT including strategy, architecture, transformation, delivery, operations & cybersecurity. Before joining AXA, Mr. Ho worked in various IT leadership role from AIA, Sun Life, IBM and Accenture. Before working in Insurance industry, Mr. Ho is the trusted management consultant / lead architect stationed in APAC & EMEA countries for various digital and cloud transformation projects from strategy to execution in financial services sector. Mr. Ho holds an MBA degree from University of Iowa - Henry B. Tippie School of Management in US. He is also a TOGAF Certified Enterprise Architect, IBM Certified Senior IT Architect - API Integration Specialization, IBM Certified Cloud Solution Advisor and Microsoft Certified Azure Cloud Specialist.'
              ],
              topic_title: 'Digital Ecosystem enabled by API & Big data'
            },
            {
              data_target: 'garylModal',
              image_url: 'garyl-pro.jpg',
              name: 'Gary Lam',
              job_title: 'Chief Technology Officer of Livi Bank',
              company: 'Livi Bank',
              position: 'Chief Technology Officer',
              about: [
                'Gary joins us as Chief Technology Officer from Morgan Stanley where he was Executive Director, Head of Digital and Platform Architecture for Private Wealth Management business, and prior to that he served as Head of Program Management and leading Agile Transformation (APAC) for major banks including HSBC and JP Morgan.',
                'Gary brings 20 years of experience in leading large IT teams across APAC and various projects in Fintech products, including Trade Finance platform powered by blockchain, Wechat banking for corporate customers and international trade tracking app.'
              ],
              topic_title: 'Banking & Open APIs',
              linkedin: 'garylam'
            },
            {
              data_target: 'martinModal',
              image_url: 'martin-pro.jpg',
              name: 'Martin Liu',
              job_title: 'Assistant Director, Platform Development, STP Platform at Hong Kong Science and Technology Parks Corporation',
              company: 'Hong Kong Science and Technology Parks Corporation',
              position: 'Assistant Director, Platform Development, STP Platform',
              about: [
                'Mr. Martin Liu has over 10 years of working experience in the artificial intelligence industry. He is a seasoned IT professional with extensive experience in developing computer vision solutions and in-depth knowledge of product development. Martin currently is the associate director and was the AI technical lead of STP Platform, Hong Kong Science and Technology Parks Corporation (HKSTP).',
                'Before joining HKSTP, Martin led the system development team of an AI company in developing computer vision solutions for diamond-related technology, smart retails, and facial recognition technology. He is the inventor of 4 patents. He won the Red Dot Award for the patented diamond viewer with the algorithm for viewing nanoscale marking and specific 8A8H patterns on a diamond.',
                'Martin holds a Master Degree in E-Commerce and Internet Computing, and a Bachelor Degree in Computer Engineering from the University of Hong Kong.'
              ],
              topic_title: 'The Open API Ecosystem',
              linkedin: 'martin-liu-522ab63b'
            },
            {
              data_target: 'toaModal',
              image_url: 'toa-pro.jpg',
              name: 'Dr. Toa Charm',
              job_title: 'Associate Professor of Practice in Innovation and Technology at CUHK Business School',
              company: 'CUHK Business School',
              position: 'Associate Professor of Practice in Innovation and Technology',
              topic_title: 'Critical Role of Data in Platform Economy',
              linkedin: 'toacharm'
            },
            {
              data_target: 'jimmyModal',
              image_url: 'jimmy-pro.jpg',
              name: 'Jimmy Tsang',
              job_title: 'EASG Committee at Hong Kong Computer Society',
              company: 'Hong Kong Computer Society',
              position: 'EASG Committee',
              topic_title: 'The API Trends and Beyond',
              linkedin: 'jimmy-tsang-ab772b1'
            },
            {
              data_target: 'nelsonModal',
              image_url: 'nelson-pro.jpg',
              name: 'Nelson Chan',
              job_title: 'Head of Financial Services Business Development, Hong Kong & Taiwan at AWS',
              company: 'AWS',
              position: 'Head of Financial Services Business Development, Hong Kong & Taiwan',
              topic_title: 'Innovation and Impact - Adopting APIs for enterprise transformation',
              linkedin: 'nelsonchanau'
            },
            {
              data_target: 'easonModal',
              image_url: 'eason-pro.jpg',
              name: 'Eason Lai',
              job_title: 'Global Technology Strategist at Microsoft',
              company: 'Microsoft',
              position: 'Global Technology Strategist',
              about: [],
              about_special: '<span class="d-block mb-3">A seasoned Principle Digital Solution Architect with over 20 years of experience in application development, cloud technologies, digital solutions, big data solutions, data analytics & science, and experiences in supporting various industries enterprises. Working experience includes multinational technology giant companies & financial institutions, Microsoft & IBM, and AIA Hong Kong & Macau. As a technical evangelist and strategist, he is very active on public speech, webinars, and contribution of technical articles, and open-source code repositories, whose topics include Artificial Intelligence, Machine Learning, Data Analytics & Science, Solution Design & Best Practices with Microsoft Azure, Azure Cognitive Services, Azure Machine Learning, and Azure Databricks. Also, he mentored data analytics and science specialization courses in Coursera (<a href="http://deeplearning.ai/" target="_blank">Deeplearning.ai<a>).</span>',
              topic_title: 'Serving Data as API',
              topic_description: [
                'In the era of Big Data, data is essential for modern digital applications. Data must be processed, curated, and refined before it can serve. But how do we effectively serve the refined data and allow the consumer to consume or integrate easily? That\'s the trending topic of Data Virtualization.',
                'In this session, I will share with you the concept, architecture, and tools for Serving Data as API.'
              ],
              linkedin: 'eason-lai-8618a235',
              twitter: 'easonlaiwork'
            },
            {
              data_target: 'cliveModal',
              image_url: 'clive-pro.jpg',
              name: 'Clive Chan',
              job_title: 'Principal Solution Architect at Openhive',
              company: 'Openhive',
              position: 'Principal Solution Architect',
              about: [
                'Clive has over twenty years of experience in technical consulting and business development. By working in a variety of technical roles for Systems Integrators and IT Vendors, Clive has gained robust and diverse experience in multi-vendor integration projects and architecture design for enterprise customers.',
                'Clive has extensive knowledge of enterprise architecture and technology spanning from Secure multi-party computation, multi-cloud infrastructure, DevOps, distributed application and API security. In addition, he has personally been involved in designing and deploying many of the largest and most innovative enterprise infrastructures in Hong Kong and the Region.',
                'Clive has worked at Fujitsu, Sun Microsystems, Oracle and F5 Networks. He holds a B.Eng. (Electrical & Electronics Engineering) from the University of Hong Kong.'
              ],
              topic_title: 'Create Value and Insight Through Privacy-preserving Data Collaboration with Federated Learning',
              topic_description: [
                'By 2025, 60% of large organizations will use one or more privacy-enhancing computation techniques in analytics, business intelligence or cloud computing.',
                'Privacy-enhancing computation (PEC) approaches allow data to be shared across ecosystems, creating value but preserving privacy.',
                'Source: Top Strategic Technology Trends for 2022, Gartner',
                'In this session, I will explain the concept and application of federated learning and how federated learning provides a secure computing framework based on data privacy protection and supports secure computation, including machine learning.',
                'In addition, I will share insights on the adoption and development of federated learning solutions in Hong Kong.'
              ],
              linkedin: 'clive-chan-hk'
            },
            {
              data_target: 'issacModal',
              image_url: 'issac-pro.jpg',
              name: 'Issac Wong',
              job_title: 'Principal AI Solution Architect at Openhive',
              company: 'Openhive',
              position: 'Principal AI Solution Architect',
              topic_title: 'Data collaboration with Federated Learning',
              topic_description: [
                'Shed light on the motivation, unique value proposition and adoption of Federated Learning for data collaboration'
              ],
              linkedin: 'dataminor'
            },
            {
              data_target: 'wilsonModal',
              image_url: 'wilson-pro.jpg',
              name: 'Wilson Wong',
              job_title: 'Senior Solutions Architect at AWS',
              company: 'AWS',
              position: 'Senior Solutions Architect',
              topic_title: 'API Modernization with GraphQL',
              linkedin: 'wilsonhfwong'
            },
            {
              data_target: 'alvinModal',
              image_url: 'alvin-pro.jpg',
              name: 'Alvin Tam',
              job_title: 'EASG Committee at Hong Kong Computer Society',
              company: 'Hong Kong Computer Society',
              position: 'EASG Committee',
              topic_title: 'Attack API Architecture',
              linkedin: 'alvin-tam-a3010527'
            },
            {
              data_target: 'rayModal',
              image_url: 'ray-pro.jpg',
              name: 'Ray Yip',
              job_title: 'EASG Committee at Hong Kong Computer Society',
              company: 'Hong Kong Computer Society',
              position: 'EASG Committee',
              topic_title: 'APIs + Data integration capabilities as tools for building faster and adaptive digital solutions',
              linkedin: 'rayyip2'
            },
            {
              data_target: 'joeyModal',
              image_url: 'joey-pro.jpg',
              name: 'Joey Wong',
              job_title: 'Customer Engineer at Google',
              company: 'Google',
              position: 'Customer Engineer',
              about: [
                'Joey Wong is a technologist who has worked across multiple industries in Australia and North America, helping companies build new delightful customer experiences and creating new digital revenues.'
              ],
              topic_title: 'Insights As a Service with APIs',
              linkedin: 'j6g'
            },
            // {
            //   data_target: 'michaelModal',
            //   image_url: 'michael-pro.jpg',
            //   name: 'Michael Chen',
            //   job_title: 'VP of Solutions Architecture and Professional Services at Cloud Mile Limited',
            //   company: 'Cloud Mile Limited',
            //   position: 'VP of Solutions Architecture and Professional Services',
            //   about: [
            //     'Dr. Michael Chen is the VP of Solutions Architecture and Professional Services in Cloud Mile Limited. He is also the Executive Committee Member of the Enterprise Architecture Specialist Group (EASG) for Hong Kong Computer Society, and VP of Young Development of PMI Hong Kong Chapter. He has 20+ years of experience in FinTech & Cloud experiences taking leading roles in consulting, professional services, project management, program development, pre-sales, marketing, training and research. Prior to joining CloudMile, he led various large-scale cloud transformation projects for enterprise and global financial clients. He obtained the PhD degree in Computer Science from Hong Kong Baptist University.'
            //   ],
            //   topic_title: 'API Distribution Strategy for Digital Excellence',
            //   topic_description: [
            //     'Traditional banks are facing market pressures like regulation, customer expectation, time to market, competitors and openness, etc. We manage API as products which are the foundation for both banking and partner digital experiences. The session starts from 4 commandments of API distribution strategy, then explains how clients (esp. banking industry) can achieve excellence in digital transformation journey by adopting API Design-First approach with reference cases on Apigee platform.'
            //   ],
            //   linkedin: 'michaelcxw'
            // },
            {
              data_target: 'patrickModal',
              image_url: 'patrick-pro.jpg',
              name: 'Patrick C.S. Fan',
              job_title: 'CEO & Founder of beNovelty Limited',
              company: 'beNovelty Limited',
              position: 'CEO & Founder',
              about: [
                'Patrick Fan is a serial entrepreneur and CEO of beNovelty Limited - an award-winning API first company. Patrick is a recognized thought leader in API space and the Hong Kong organizer & representatives of APIDays - the world\'s Leading API Conference Series. He is an active participant & speaker of major API Developer Communities in different regions. Patrick is current an entrepreneur-in-residency (EIR) of a venture capital and providing API advisory to various local startups & enterprise across sectors to adopt API first strategy. With his exceptional knowhow in Open API domain, Patrick is part of the Hong Kong API Startup Registration Scheme workgroup and is appointed by HKQAA as an associate trainer in API Cybersecurity & Information Security.'
              ],
              topic_title: 'Open Banking is an "How" and Embedded Finance is an "Why"',
              linkedin: 'patrickcsfan',
              twitter: 'patrickcsfan'
            },
            {
              data_target: 'daleModal',
              image_url: 'dale-pro.jpg',
              name: 'Dale Lane',
              job_title: 'Senior Technology Engineer at IBM',
              company: 'IBM',
              position: 'Senior Technology Engineer',
              about: [
                'Dale is a senior Technology Engineer in IBM Client Engineering, responsible for working with IBM clients to create things using technology - with a particular focus on applying the IBM Garage Methodology to deliver innovative and transformational projects. He has a background in Apache Kafka and IBM MQ, and is a member of the Technical Steering Committee for the AsyncAPI standard.'
              ],
              topic_title: 'Add your first asynchronous API to your app in 30 minutes',
              topic_description: [
                'Introducing asynchronous APIs may seem too complex to take on. By bringing together data-centric and event-centric approaches, using the likes of Apache Kafka, it doesn\'t need to be as daunting as you might think.',
                'Idealistic presentations about event-driven architectures too often assume that you can start from a blank page. But you probably don\'t have that luxury. In practice, you often need to introduce elements of event-driven approaches into an existing data-centric landscape.',
                'If you\'re just getting started with event-driven architectures and asynchronous APIs, all of this can feel challenging. But it doesn\'t have to be.',
                'In this session, we will show you how to achieve a blend of data-centric and event-centric techniques in your architectures, and why that can be a benefit, especially to help you introduce asynchronous APIs into your workflow.',
                'More practically, we\'ll show you simple approaches for introducing your first asynchronous APIs and event-driven architecture patterns into your existing application. We\'ll demonstrate how you can incrementally adopt technologies like Apache Kafka into an existing environment, and start getting benefits without needing to build new applications or rebuild your existing applications.',
                'With these demos, we will give you ideas for how to take your first steps into the world of asynchronous APIs!'
              ],
              linkedin: 'dalelane',
              twitter: 'dalelane'
            },
            {
              data_target: 'nicolasModal',
              image_url: 'nicolas-pro.jpg',
              name: 'Nicolas Fränkel',
              job_title: 'Head of Developer Advocacy at Apache APISIX',
              company: 'Apache APISIX',
              position: 'Head of Developer Advocacy',
              about: [
                'Developer Advocate with 15+ years experience consulting for many different customers, in a wide range of contexts (such as telecoms, banking, insurances, large retail and public sector). Usually working on Java/Java EE and Spring technologies, but with focused interests like Rich Internet Applications, Testing, CI/CD and DevOps. Also double as a trainer and triples as a book author.'
              ],
              topic_title: 'Evolving your APIs, a step-by-step approach',
              topic_description: [
                'When you publish your first HTTP API, you\'re more focused on short-term issues than planning for the future. However, chances are you\'ll be successful, and you\' ll “hit the wall”. How do you evolve your API without breaking the contract with your existing users?',
                'In this talk, I\'ll first show you some tips and tricks to achieve that: moving your endpoints, deprecating them, monitoring who\'s using them, and letting users know about the new endpoints. The talk is demo-based, and I\'ll use the Apache APISIX project for it.'
              ],
              twitter: 'nicolas_frankel'
            },
            {
              data_target: 'jeremyModal',
              image_url: 'jeremy-pro.jpg',
              name: 'Jeremy Glassenberg',
              job_title: 'Product Management Lead, APIs at Docusign',
              company: 'Docusign',
              position: 'Product Management Lead, APIs',
              about: [
                'Jeremy is an experienced Product leader of over 14 years with a specialty in managing developer platforms and APIs at successful startups like Box and Tradeshift, building successful Platform and Product organizations from the ground up. He managed and expanded developer platforms to communities of tens of thousands of developers, and led high-profile integrations promoted by Salesforce and Google.',
                'Jeremy is an advisor and mentor to startups and accelerators such as Alchemist, Acceleprise, Techstars, and Heavybit for Product and API strategy. He is also a Product Management course instructor and author of several publications on API strategy.'
              ],
              topic_title: 'APIs for Scaling Enterprises: Setting and Applying API Standards',
              topic_description: [
                'API standards and schemas have helped to automate much of API design, implementation and maintenance -- and not a moment too soon. As many tech companies experienced growth spurts in the past year, they ended up with multiple teams working on new products and new APIs. Consequently, they learned that their ways to create well-designed APIs wouldn\'t work so easily when multiple teams have to create them.',
                'Thanks to new tools, growing companies can establish a scalable system for designing, implementing, and launching consistent APIs across many teams. We\’ll share best practices and solutions from experiences with enterprises in this phase to understand how to be effective working across Product, Infrastructure and Engineering teams to do so.'
              ],
              linkedin: 'jglassenberg'
            },
            {
              data_target: 'sonalModal',
              image_url: 'sonal-pro.jpg',
              name: 'Sonal Rattan',
              job_title: 'Chief Technology Officer at eXate',
              company: 'eXate',
              position: 'Chief Technology Officer',
              about: [
                'Sonal Rattan is the Co-founder and Chief Technology Officer of eXate, which is now a multiple award-winning data privacy firm, that specialises in automating and applying privacy and security to sensitive data in the right place and in the right form.',
                'Prior to starting eXate in 2015, Sonal held senior positions at global bank HSBC for over 5 years. In her previous roles as EMEA Markets Head of RegTech and later the Head of Digital Assets at HSBC, Sonal was tasked with processing information according to data privacy and financial regulations. Conscious that this was a challenge for other banks, Sonal Co-Founded eXate to tackle this challenge. It was in Sonal\'s interest to ensure that data privacy is easy for software developers so that there is no reason to not factor in data privacy. ',
                'Over the years Sonal has led a highly skilled team of developers and architects in helping build the eXate platform, successfully deploying the eXate solution. In addition to her work developing the data privacy platform.'
              ],
              topic_title: 'Digital Transformation - Potential Pitfalls, Protection and PET\'s (Privacy Enhancing Techniques)',
              topic_description: [
                'Sonal Rattan discusses the evolution of digital transformation and how Covid has accelerated API adoption by 7 years. In addition, she shares how eXate, through its APIgator technology, helps to tackle the potential pitfalls of digital transformation by automating privacy and security to ensure that the right job functions see the right sensitive data in the right location at the right time.'
              ],
              linkedin: 'sonalrattan',
              twitter: 'ExateTech'
            },
            {
              data_target: 'giriModal',
              image_url: 'giri-pro.jpg',
              name: 'Giri Venkatesan',
              job_title: 'Developer Advocate at Solace',
              company: 'Solace',
              position: 'Developer Advocate',
              about: [
                'Giri is a Developer Advocate with extensive experience in various technical domains, including Integration and Master Data Management. He started his engineering journey in the classic EAI & B2B Integration space and has been a part of the Integration evolution culminating in modern EDA, Microservices, Spring, and other Low-code/no-code frameworks. He has a keen interest in building and promoting creative solutions and is a huge fan of open-source standards and applications. He is excited to identify and explore tools and frameworks to aid Businesses in their quest for achieving efficiency and increased productivity.'
              ],
              topic_title: 'Event API Products: Maximizing the Value of Your Event-Driven Architecture',
              topic_special: '<p>Every change of state in an enterprise is qualified as an \'event\'. Like synchronous REST APIs, it is possible to elevate asynchronous, event-centric interactions as event APIs to streamline business processes and create positive customer experiences. Enabling and exposing events in an enterprise as event API products can benefit internal and external stakeholders. To accomplish that, an enterprise needs the ability to:</p><ul class="mb-3"><li>Harness and expose digital value as events</li><li>Bundle and document events for sharing and automation using specifications such as AsyncAPI</li></li>Release the event API product to the world</li><li>Manage event API products using an event management platform that simplifies the entire event lifecycle</li></ul><p>Adoption of event API products will benefit from the experience of working with synchronous APIs, existing standards, and API management systems - eventually making it an integral part of complex problem-solving.</p><p>This talk includes a demonstration of building and consuming an event API product using Solace PubSub+ and AsyncAPI.</p>',
              linkedin: 'girivenkatesan',
              twitter: 'giri_venkatesan'
            },
            {
              data_target: 'zhiyuanModal',
              image_url: 'zhiyuan-pro.jpg',
              name: 'Zhiyuan Ju',
              job_title: 'Head of Global at API7.ai',
              company: 'API7.ai',
              position: 'Head of Global',
              about: [],
              about_special: '<span class="d-block mb-3">Apache Member, Apache APISIX PMC Member, Head of Global at <a href="http://api7.ai/" target="_blank">API7.ai</a>.</span>',
              topic_title: 'Why is API Gateway essential to business?',
              topic_description: [],
              topic_special: '<ul><li>API evolution process (from monolithic to microservices)</li><li>API evolution brings architectural challenges (how to manage many microservices and ensure stability, security, and reliability?)</li><li>How does robust API management benefit the business? What are the negative business impacts of unreliable APIs?</li><li>How to choose a suitable API gateway?</li><li>Why do people prefer open source gateway?</li></ul>',
              linkedin: 'jjzhiyuan',
              twitter: 'jjzhiyuan'
            },
            {
              data_target: 'uliModal',
              image_url: 'uli-pro.jpg',
              name: 'Uli Hitzel',
              job_title: 'Founder of Electric Minds',
              company: 'Electric Minds',
              position: 'Founder',
              about: [
                'Uli has been working with data engineering & distributed systems since the early days of the internet. Previously with companies including Microsoft, Red Hat, Yahoo! and IBM, he has been covering open banking, healthcare, service providers, education, and media. Since 2020 he teaches DevOps at the National University of Singapore\'s FinTech programme.'
              ],
              topic_title: 'Let\'s build an AI-powered API in 30 Minutes',
              topic_description: [
                'Artificial intelligence helps companies across industries improve customer service and reduce risk. Recommendation engines, fraud detection, content moderation are just a few examples of how AI is being used today, usually offered on cloud-based platforms as a service. Wouldn\'t it be great if you could create these APIs yourself, without being a data scientist or machine learning engineer?',
                'In this hands-on session, participants will learn how to use GPT-3 to train a model, then design, build & deploy an API in Python or NodeJS in just twenty minutes. No prior experience with data science or machine learning is necessary. Join us and see how easy it is to make your applications smarter with AI!'
              ],
              linkedin: 'uhitzel',
              twitter: 'u1i'
            },
            {
              data_target: 'fredericModal',
              image_url: 'frederic-pro.jpg',
              name: 'Frédéric Harper',
              job_title: 'Director of Developer Relations at Mindee',
              company: 'Mindee',
              position: 'Director of Developer Relations',
              about: [
                'As the Director of Developer Relations at Mindee, Frédéric Harper helps developers merge the physical and digital worlds using the magic of machine learning coupled with the ease of APIs. Fred has shared his passion for technology on the stage at dozens of events around the world. He\'s helped build successful communities at npm, Mozilla, Microsoft, DigitalOcean, and Fitbit, and is the author of the book Personal Branding for Developers at Apress. Behind this extrovert is a very passionate individual who believes in the power of communication... and cat videos.'
              ],
              topic_title: 'Developers Documentation: your secret weapon',
              topic_description: [
                'You can have the best product in your expertise area, but if your documentation isn\'t on par with the flawless experience you want to offer to the world, success is not guaranteed. Let\'s be real here: documentation is often an afterthought and rarely included in life cycle development processes. Still, documentation is the secret weapon for greater adoption, and growth that you may have not known you could achieve.',
                'It\'s time for you to step up your game and measure up to the big players. Learn about the benefits of high quality and educational documentation and the true role it plays in the developer community. You\'ll also learn the principles of a solid foundation, and tips on how to use one of the most powerful developer relations\' tools.'
              ],
              linkedin: 'fredericharper',
              twitter: 'fharper'
            },
            {
              data_target: 'mattModal',
              image_url: 'matt-pro.jpg',
              name: 'Matt Tanner',
              job_title: 'Head of Developer Relations at Moesif',
              company: 'Moesif',
              position: 'Head of Developer Relations',
              topic_title: 'Creating Great Developer Experiences with Metrics and Automation',
              topic_description: [
                'I will talk about what metrics are important to track when looking at onboarding and product usage when creating products aimed at developers. From these metrics we will chat about creating customized onboarding, retention emails, guiding users towards features that will bring them value based on trends with other users, etc.'
              ],
              linkedin: 'matt-tanner-3267aa5a'
            },
            {
              data_target: 'sunnyModal',
              image_url: 'sunny-pro.jpg',
              name: 'Sunny Singh',
              job_title: 'Lead Security Engineer at Calibo',
              company: 'Calibo',
              position: 'Lead Security Engineer',
              about: [],
              about_special: '<span class="d-block mb-3">I am Lead Security Engineer, at Calibo. Calibo\' Lazsa Pro-Code Product Platform-as-a Service (pPaaS) simplifies and accelerates the end-to-end product development lifecycle right from conceptualisation through deployment. Sunny works as a principal security engineer at <a href="http://cybert.in/" target="_blank">cybert.in</a>. Any time left over from that is devoted to bug bounty hunting.</span>',
              topic_title: 'Offensive API Penetration Testing',
              topic_description: [
                'This is the era of cutting edge technologies where API is most crucial part. Security comes first for any organisation hence breaking APIs ourselves before Hackers do, is most important things in security nowadays. I would like to talk about multiple offensive ways to break APIs manually and with tools by following OWASP standard.'
              ],
              linkedin: 'sunny-singh-13925a130',
              twitter: '5unny__'
            },
            {
              data_target: 'robModal',
              image_url: 'rob-pro.jpg',
              name: 'Rob Dickinson',
              job_title: 'Co-founder and CTO of Resurface Labs Inc.',
              company: 'Resurface Labs Inc.',
              position: 'Co-founder and CTO',
              topic_title: 'The truth about anomaly detection in API security',
              topic_description: [
                'Much of security is focused on finding the outliers, the anomalies to provide a reliable signal for security teams. Once identified, these anomalies are considered instructive and actionable. But, with the proliferation of APIs and the volume of attack traffic every second, relying on outliers leads to exceptionally noisy and unproductive searches.',
                'You can\'t look at one facet of API interactions and know immediately if it\'s malicious traffic or valid traffic. User behaviors can exercise the full range of API interactions, and old norms no longer apply (take remote login volume spikes for example). Systems report reliably on operational metrics, and actions can only be understood when in the context of who is performing them. Security cannot rely on anything less than 360° context.'
              ],
              linkedin: 'robfromboulder'
            },
            {
              data_target: 'paulModal',
              image_url: 'paul-pro.jpg',
              name: 'Paul Brebner',
              job_title: 'Open Source Technology Evangelist at Instaclustr by NetApp',
              company: 'Instaclustr by NetApp',
              position: 'Open Source Technology Evangelist',
              about: [
                'Technology Evangelist at Instaclustr by NetApp. For the last 5 years, Paul has been learning new open source technologies, building realistic demonstration applications, writing blogs, and presenting at international conferences including FOSSASIA, All Things Open and ApacheCon. Paul has extensive R&D, teaching and consulting experience in distributed systems, technology innovation, software architecture, and engineering, software performance and scalability, grid and cloud computing, and data analytics and machine learning. Previously he worked at UNSW, several start-ups, CSIRO, UCL, NICTA and ANU. He has an MSc in Machine Learning (1st Class Honours, Waikato).'
              ],
              topic_title: 'Spinning Your Drones with Cadence Workflows and Apache Kafka',
              topic_description: [
                'The rapid rise in Big Data use cases over the last decade has been accelerated by popular massively scalable open-source technologies such as Apache Cassandra® for storage, Apache Kafka® for streaming, and OpenSearch® for search. Now there\'s a new member of the peloton, Cadence for workflows. Cadence is Uber\'s open-source code-based scalable fault-tolerant workflow orchestration technology. It is applicable for implementing workflows in business-focussed (e.g. retail or finance) and technical domains. This talk will illustrate the most important Cadence concepts and APIs using a realistic drone delivery service demonstration application. We\'ll also explore how to integrate Cadence workflows and Apache Kafka events, thereby combining orchestration and choreography architectural styles. Finally, we\'ll test out Cadence\'s scalability promise by seeing how many drones we can fly at once.'
              ],
              linkedin: 'paul-brebner-0a547b4'
            },
            {
              data_target: 'mottiModal',
              image_url: 'motti-pro.jpg',
              name: 'Motti Bechhofer',
              job_title: 'Senior Software Engineer at Aspecto',
              company: 'Aspecto',
              position: 'Senior Software Engineer',
              about: [
                'Motti served as a software developer in the Israeli Air Force, developing post-flight debriefing & training software. Before joining Aspecto, Motti worked in multiple startups and was responsible for building and scaling their infrastructure. Among them, was a Lead Software Engineer at Chorus. Currently, Motti is a software developer at Aspecto, a distributed tracing platform.'
              ],
              topic_title: 'The State of Observability',
              topic_description: [
                'With the accelerated growth of scale and complexity in distributed architectures, the pressure to detect abnormal behavior, identify why it occurred, quickly fix it, and prevent similar issues from happening again, is rapidly growing.',
                'Here is where observability comes into play. Observability is the ability to get insights into the performance of your cloud-native applications. It gives you a deep inside look into what\'s happening within your distributed system, empowering you to not only know that something happened but also why, as well as what you can do to fix it and improve its performance. From pre-production to deployment.',
                'Observability adoption is in the early stages but rapidly gaining popularity among giants like AWS, Google Cloud, Datadog, and more.',
                'In this session, we will explore the future and trends of observability and cloud-native development. We\'ll introduce you to the three pillars of observability (metrics, traces, and logs) and show how developers can use them to make observability more actionable and scalable in their systems.'
              ],
              linkedin: 'motti-bec',
              twitter: 'motti_bec'
            },
            {
              data_target: 'maryModal',
              image_url: 'mary-pro.jpg',
              name: 'Mary Grygleski',
              job_title: 'Senior Streaming Developer Advocate at DataStax',
              company: 'DataStax',
              position: 'Senior Streaming Developer Advocate',
              about: [
                'Mary is a Java Champion and a passionate Streaming Developer Advocate at DataStax, a leading data management company that champions Open Source software and specializes in Big Data/NoSQL, DB-as-a-service, Streaming, and Cloud-Native systems. She spent 3.5 years previously as a very effective advocate at IBM, focusing on Java, Jakarta EE, OpenJ9, Open Source, Cloud, and Distributed Systems. She transitioned from Unix/C to Java around 2000 and has never looked back since then. She considers herself a polyglot and loves to continue learning new and better ways to solve real-life problems. She is an active tech community builder outside of her day job, and currently the President of the Chicago Java Users Group (CJUG), as well as a co-organizer for several IBM-sponsored meetup groups in the Greater Chicago area.'
              ],
              topic_title: 'Leveraging Event Streaming to Super-Charge your Business Solutions',
              topic_description: [
                'The world is moving at an unprecedented pace and much of it has been powered by the innovations in software and systems. While event handling, messaging, and processing are not necessarily brand new concepts, the recent emergence in hardware such as virtualizations, multi-core processors, and so on, are in fact pushing the envelope in software design and development, elevating it to higher levels of capabilities never seen before. In the case of streaming which very often leverages on the underlying messaging mechanism(s) to bring distributed messaging to higher forms of purposes, such as IoT/IIoT applications, AI/ML data pipelines, or even eCommerce recommendations, event streaming platform has indeed become the “glue” in enabling data to flow through disparate systems in the pipeline and in a very dynamic fashion.',
                'This talk on event streaming is meant for anyone interested in learning about it, and understanding how it fits into the modern software development design and architecture, as well as seeing some of the challenges it faces especially in the Cloud Native environment. We\'ll then take a look at an open source platform - Apache Pulsar, which is poised to become the de facto new generation of distributed messaging and streaming platform that will bring joy to developers, and enable systems and applications to be highly responsive with its true real-time capabilities.'
              ],
              linkedin: 'mary-grygleski',
              twitter: 'mgrygles'
            },
            {
              data_target: 'derricModal',
              image_url: 'derric-pro.jpg',
              name: 'Derric Gilling',
              job_title: 'CEO at Moesif',
              company: 'Moesif',
              position: 'CEO',
              about: [
                'Derric Gilling is the CEO of Moesif, the leading API analytics and monetization platform. He enjoys working with both startups and large enterprises on launching and scaling an API-first strategy. Gilling is a frequent speaker at developer conferences, including API World, Developer Week, APIDays, and DevRelCon. Gilling has also authored various reports on APIs, including O\'Reilly and Nordic APIs.'
              ],
              topic_title: 'Best practices when monetizing APIs',
              topic_description: [
                'How to monetize a new or existing API. Framework that is required to ensure customers are successful and do not get surprised by an expensive API bill.'
              ],
              linkedin: 'derricgilling',
              twitter: 'd_gilling'
            },
            {
              data_target: 'bradModal',
              image_url: 'brad-pro.jpg',
              name: 'Brad Drysdale',
              job_title: 'Field CTO for APJ at Kong',
              company: 'Kong',
              position: 'Field CTO for APJ',
              topic_title: 'How to Build and Leverage APIs Sustainably to Reduce Digital Waste',
              topic_description: [
                'Within the next 10 years, it\'s predicted that 21% of all the energy consumed in the world will be by IT. Our mandates to digitally transform mean we\'re patting ourselves on the back celebrating new ways we delight our customers, fuelled by electricity guzzled from things our planet can\'t afford to give. 80% of the world\'s electricity is still fossil based: how much is digital transformation costing future generations?',
                'APIs are hugely important here. Cisco estimates that global web traffic in 2021 exceeded 2.8 zettabytes. That equates to 21 trillion MP3 songs, or 2,658 songs for every single person on the planet. It\'s almost 3x the number of stars in the observable universe. And now consider that 83% of this traffic is through APIs!',
                'In this talk, we\'ll examine how we should use, build, deploy, and innovate on top of APIs to support sustainability goals, and discuss how to address the environmental impact of architecture trends such as moving from monolith to microservices.'
              ],
              linkedin: 'brad-drysdale'
            },
            {
              data_target: 'mehdiModal',
              image_url: 'mehdi-pro.jpg',
              name: 'Mehdi Medjaoui',
              job_title: 'Founder of apidays conference',
              position: 'Founder of apidays conference',
              about: [],
              about_special: '<span class="d-block mb-3">Mehdi Medjaoui is the founder of APIdays conferences series worldwide, that he started in 2012 in Paris. He is highly involved in the API community and API Industry, and is a current author, lecturer, consultant and investor in the API space. In 2011 he co-founded <a href="http://oauth.io/"  target="_blank">OAuth.io</a>, a SaaS OAuth API middleware for OAuth integration and implementation used by 40,000+ developers, that had been acquired in December 2017. His research involves publishing the API Industry Landscape and the yearly State of Banking APIs.',
              topic_title: 'The API Landscape',
              linkedin: 'mehdimedjaoui'
            },
            {
              data_target: 'steveModal',
              image_url: 'steve-pro.jpg',
              name: 'Steve Ng',
              job_title: 'Developer Relations Lead APJ at New Relic',
              company: 'New Relic',
              position: 'Developer Relations Lead APJ',
              about: [],
              about_special: '<p>Right now as a Developer Relations Lead at New Relic (also known as developer advocacy), Steve is passionate about helping developers be successful with the tools they use and act as a bridge between product engineering, partnership, and the broader developer community.</p><p>Steve is an experienced customer solution engineer with over 10 years experience working in the Asia Pacific region with a blend of technical knowledge, customer advocacy, and intrapreneurship roles. A degree in Computer Systems Engineering from University of Queensland and his background as a backend performance engineer helped him to find his calling in solutions engineering, and he loves what he does!</p><p>Steve likes to tinker with code and serves as a mentor with Sydney Volunteer Programmers for Python (<a href="https://www.code.sydney" target="_blank">www.code.sydney</a>)</p>',
              topic_title: 'The Story of OpenAI GPT3 - Improve Observability with Tracing',
              topic_title_2: 'Open Source Instrumentation - The Rise of OpenTelemetry',
              topic_description: [
                'OpenAI\'s mission is to ensure that artificial intelligence benefits all of humanity. This effort is essential to train AI systems to do what humans want. With the democratizing of services into consumable API for developers, it\'s easy for companies to build AI offerings. This talk will discuss the latest development in AI and improve Observability with tracing on OpenAI.'
              ],
              topic_description_2: [
                'OpenTelemetry is the second most popular project in CNCF for Observability. OpenTelemetry’s Mission is to enable effective observability by making high-quality and portable telemetry ubiquitous. Effective observability is powerful because it allows developers to innovate faster while maintaining high reliability. But effective observability requires high-quality telemetry - and the performant, consistent instrumentation that makes it possible. Continuing from the main talk on tracing, we will explore core open-source instrumentation concepts and how tracing can be used to pinpoint delays in API transactions.'
              ],
              linkedin: 'sng08'
            },
            {
              data_target: 'enriqueModal',
              image_url: 'enrique-pro.jpg',
              name: 'Enrique Goizueta',
              job_title: 'Head of Sales, APAC at Meniga',
              company: 'Meniga',
              position: 'Head of Sales, APAC',
              about: [
                'Enrique Goizueta leads Meniga\'s growth in the Asia-Pacific region (APAC). Operating with a start-up mindset, Enrique is heading sales and business development efforts to continue expanding Meniga\'s presence in APAC, develop new channels, and contribute to the company\'s marketing and business strategies.',
                'Founded in Iceland in 2009, Meniga is a global market leader helping Banks, FinTechs, Neobanks, eWallets, and Digital Challengers on a journey to support their clients developing healthier financial habits through fun personalized digital engagements.',
                'Recognized as an Open Banking and Sustainability leader in Europe, financial institutions across Asia are implementing Meniga to launch Environmental, Social and Corporate Governance (ESG) strategies that promote green banking solutions and enable environmentally conscious customers to become more sustainable.',
                'Enrique has held several leadership roles in Madrid, London, New York, San Francisco, Mexico and now Singapore, delivering exceptional value to clients through business and technology transformations at Fujitsu, TIBCO, Sngular, or lately founding his own startup Buzziler.',
                'Born in Spain, Enrique holds a Bachelor\'s degree in Computer Engineering from the Technical University of Madrid, and several executive programs from Chicago Booth and Wharton School of Business.'
              ],
              topic_title: 'Matchmaking ESG & Sustainability with Open Banking APIs',
              topic_description: [
                'Learn how leading digital banks are connecting Carbon footprint APIs & Open Banking APIs to create an impactful digital experience around Sustainability and Green banking products.'
              ],
              linkedin: 'enriquegoizueta',
              twitter: 'enriquegoizueta'
            },
            {
              data_target: 'giovanniModal',
              image_url: 'giovanni-pro.jpg',
              name: 'Giovanni Lesna',
              job_title: 'Head of Enterprise & Partnerships at API3',
              company: 'API3',
              position: 'Head of Enterprise & Partnerships',
              about: [
                'Giovanni Lesna is the Head of Enterprise and Partnerships at API3 working with Fortune 2000 companies to make their Web 3.0 ambitions a reality. He has an MBA in Digital Business Transformation (UB), a Masters in Blockchain Technologies (UB) and is a Certified Management Consultant (CMC). Prior to Web3.0, his domain of expertise is in traditional financial and capital markets where he worked with the largest bank in Africa as a Consultant. Additionally, Giovanni has worked within 4th industrial revolution (4IR) technology verticals, passionate about 4IR and its ability to unlock human evolution.'
              ],
              topic_title: 'Connecting to Web3 - DeFi and beyond',
              topic_description: [
                'A small abstract: This session will cover a brief introduction of the evolution of the Web from its first iteration through Web2 and now, Web3. We will explore what Web3 is and how it has enabled smart contracts, DeFi, NFTs and beyond. The opportunities and risks will be discussed with a focus on how your business can monetize your data to Web3, powered by APIs. Lastly, we discuss how your Web2 business can tap into this growing ecosystem via an API gateway (API3).',
                'The goal of the session is to walk away with a holistic view of the state of the Web and how your API strategy could be monetized for Web3.'
              ],
              linkedin: 'giovanni-lesna',
              twitter: 'giannilesna'
            },
            {
              data_target: 'simonModal',
              image_url: 'simon-pro.jpg',
              name: 'Simon Redfern',
              job_title: 'CEO and Founder of TESOBE / The Open Bank Project',
              company: 'TESOBE / The Open Bank Project',
              position: 'CEO and Founder',
              about: [
                'Pioneering Open Banking since February 2010, Simon is the CEO of TESOBE and founder of the Open Bank Project, the open source API platform for banks. Simon studied electronic engineering in Manchester in the early 80\'s where he focused on real time programming and digital systems - and he subsequently worked as a software developer, architect, sysadmin and DBA on numerous web / mobile platforms both large and small.',
                'A hacker at heart, Simon is also a composer and enjoys interdisciplinary creative collaborations. Simon is a keen supporter of open source and open data believing they are a sustainable force for social good.'
              ],
              topic_title: 'Transforming the Bank: API-driven banking beyond Open APIs',
              topic_description: [
                'Today, institutions focus on exposing APIs to third parties to create or enhance fintech products and services. But this only scratches the surfaces of what is possible with APIs. Follow Simon Redfern as he reveals how banks and other financial institutions can streamline internal processes and modernize their systems using APIs.'
              ],
              linkedin: 'simon-redfern-78a43b1',
              twitter: 'simsysims'
            },
            {
              data_target: 'stevenModal',
              image_url: 'steven-pro.jpg',
              name: 'Steven Patrick',
              job_title: 'Technology Engineer at IBM',
              company: 'IBM',
              position: 'Technology Engineer',
              about: [
                'I have over 5 years in the technology industry, working originally in Technology Consulting (mainly with cloud solutions) and now shifted to IBM\'s Client Engineering practice. We work on short MVPs for client\'s to provide real-life proof that a specific service (or set of services) can match their needs. I\'m originally an apprentice so went into the corporate world straight from college and it\'s been fantastic, and I\'m very excited to share some cool tech walkthrough\'s with Dale at this session!'
              ],
              topic_title: 'Add your first asynchronous API to your app in 30 minutes',
              topic_description: [
                'Introducing asynchronous APIs into your architecture may seem like a complex task that no one wants to take on. But by bringing together data-centric and event-centric approaches, using the likes of Apache Kafka, this process doesn\'t need to be as daunting as one might think.'
              ],
              linkedin: 'steven-patrick-9a0b9614a'
            },
            {
              data_target: 'bobModal',
              image_url: 'bob-pro.jpg',
              name: 'Bob Brauer',
              job_title: 'CEO & Founder of Interzoid',
              company: 'Interzoid',
              position: 'CEO & Founder',
              about: [
                'Bob Brauer is the Founder & CEO of Interzoid, an API and data platform company focused on the next decade of APIs and Contextual Machine Learning for better data quality and highly usable Cloud data. Prior to Interzoid, Bob was the Founder, Chief Technology Officer, and Chief Strategy Officer of StrikeIron (now part of Informatica), and prior to that, the Founder & CEO of DataFlux (acquired by SAS).'
              ],
              topic_title: 'Cloud Databases, ELT, and APIs - A Perfect Storm?',
              topic_description: [
                'The data landscape is changing rapidly. The explosion of Cloud database platforms on to the scene the past few years has paved the way for a new class of data innovation. The ease at which data can be collected, stored, shared, and manipulated is driving a new breed of Analytics, AI/ML, Data Science, and other data-driven applications that will have a major impact on the economy. These trends have enabled the emergence of ELT as an alternative to ETL, which in turn is enabling a whole new way of thinking about warehousing data and how to make use of that data, creating new opportunities that will help organizations increase the value of the strategic data assets that drive their business. APIs are the ideal mechanism to accelerate and expand these trends even further, leading to the long-sought-after golden age of APIs. This talk will discuss the future of Cloud Databases and ELT, and how the combination will drive a substantial wave of API adoption.'
              ],
              linkedin: 'bobbrauer'
            },
            {
              data_target: 'kennethModal',
              image_url: 'kenneth-pro.jpg',
              name: 'Kenneth Cheung',
              job_title: 'Staff Solutions Engineer at Confluent',
              company: 'Confluent',
              position: 'Staff Solutions Engineer',
              about: [
                'Kenneth Cheung has over 20-year experience in the IT industry. Mission is to help enterprises transform with Digital First strategy. He has been one of the pioneers who brought Real-time Event Streaming, Big Data analytics and Data Discovery solutions to the APAC markets. For the last 10+ years, he has been focusing on educating Greater China markets with a change of the way we do business analysis and has successfully brought Data Discovery into numerous enterprises ranging from Financial Services, Retail and Manufacturing. Largest project touches the hands of over thousands of users in a single enterprise. Prior to Business Analytics, Kenneth has spent over 5 years in the field of Information Security consulting where he secured many enterprises by delivering consultancy and audit services.'
              ],
              topic_title: 'Data Mesh and Data API',
              topic_description: [
                'Data mesh is a relatively recent term that describes a set of principles that good modern data systems uphold. A kind of “microservices” for the data-centric world. In this presentation, we share our recommendations and picks of what every developer should know about building a streaming data mesh with Kafka. We introduce the four principles of the data mesh: domain-driven decentralization, data as a product, self-service data platform, and federated governance. These data products could be provided to end users through self-service Data API.'
              ],
              linkedin: 'kennethcb'
            },
            {
              data_target: 'sanvyModal',
              image_url: 'sanvy-pro.jpg',
              name: 'Sanvy Sabapathee',
              job_title: 'Senior Solutions Engineer at Confluent',
              company: 'Confluent',
              position: 'Senior Solutions Engineer',
              about: [
                'Before I was in Data I was in the Devops/Automation field with heavy leanings in full stack automation. I now use these practices with Data in order to leverage the best of both worlds'
              ],
              topic_title: 'Stream Processing with KSQLDB and REST API Queries',
              topic_description: [
                'In this workshop we will be running stream processing examples with KSQL DB'
              ],
              linkedin: 'sanvy-s-0476261'
            },
            {
              data_target: 'jeffreyModal',
              image_url: 'jeffrey-pro.jpg',
              name: 'Jeffrey Lam',
              job_title: 'Staff Solutions Engineer at Confluent',
              company: 'Confluent',
              position: 'Staff Solutions Engineer',
              about: [
                'Jeffrey Lam is big data specialist, pre-sales and enterprise architect with 20+ year experience with global technology leaders, namely Confluent, Splunk, ServiceNow, IBM and Oracle. Jeffrey has solid consulting skills, able to drive technology thought leadership at C-level in multiple disciplines such as data streaming, big data strategy, MLOps, cloud, and enterprise architecture solutions.'
              ],
              topic_title: 'Optimize Your API with Event-Driven Microservice Architecture',
              topic_description: [
                'Companies now run global businesses that span the globe and hop between clouds in real-time, breaking down data silos to create seamless applications that synergize the organization.   Enter event-driven architecture (EDA), a type of software architecture that ingests, processes, stores, and reacts to real-time data as it\'s being generated, optimzing conventional API architecture.  In this roundtable, we will share the experience of our real-life customers,  look at where we\'ve been as an industry with API and then a look at the future of event-driven systems and the benefits they bring.'
              ],
              linkedin: 'jeffrey-lam-9b65aa1'
            },
            {
              data_target: 'developersteveModal',
              image_url: 'developersteve-pro.jpg',
              name: 'DeveloperSteve Coochin',
              job_title: 'Senior Developer Advocate at Lumigo',
              company: 'Lumigo',
              position: 'Senior Developer Advocate',
              about: [
                'DeveloperSteve comes from a strong background as a developer and speaker with over 25 years industry experience. A lifelong technology enthusiast, learner and open source contributor, he loves getting hands on with new technologies to push it to its limits. DeveloperSteve continues to work closely with developer, security and cloud communities throughout the world to encourage knowledge sharing and contributing back.'
              ],
              topic_title: 'Deploying and building SDKs as a service',
              topic_description: [
                'Maintaining and developing SDKs for a multiple of languages and platforms can be time consuming and laborious. While there are many ways to tackle such a feat, using open api spec, the biggest issue I\'ve come across is generating SDKs that consume your service in a way that developers can use them. In this talk we are going to look at some ways to create your own customisable SDK generation service, Utilising openapi generator tools and ways to test against multiple language types.'
              ],
              linkedin: 'developersteve',
              twitter: 'developersteve'
            }
          ]
        }
      ],
      sponsor_list: [
        {
          type: 'GOLD SPONSORS',
          css_class: 'w-100 w-md-80 w-lg-65 mx-auto',
          sponsor: [
            {
              css_class: 'col-sm-12',
              image_css: 'max-w-35rem',
              image_url: 'confluent.png'
            }
          ]
        },
        {
          type: 'SILVER SPONSORS',
          css_class: 'w-100 w-md-80 w-lg-65 mx-auto',
          sponsor: [
            {
              css_class: 'col-sm-6 mb-1 mb-sm-0',
              image_css: 'max-w-23rem',
              image_url: 'aws.png'
            },
            {
              css_class: 'col-sm-6',
              image_css: 'max-w-23rem',
              image_url: 'new-relic.png'
            }
          ]
        },
        // {
        //   type: 'BRONZE SPONSORS',
        //   css_class: 'w-100 w-md-60 w-lg-50 mx-auto',
        //   sponsor: [
        //     {
        //       css_class: 'col-sm-12',
        //       image_css: 'max-w-15rem',
        //       image_url: 'amadeus.png'
        //     }
        //   ]
        // },
        {
          type: 'BRONZE & COMMUNITY SPONSORS',
          css_class: 'w-100 w-md-80 w-lg-65 mx-auto',
          sponsor: [
            {
              css_class: 'col-sm-4 mb-1 mb-sm-0',
              image_css: 'max-w-19rem',
              image_url: 'hkcs.png'
            },
            {
              css_class: 'col-sm-4 mb-1 mb-sm-0',
              image_css: 'max-w-19rem',
              image_url: 'hkstpsaa.png'
            },
            {
              css_class: 'col-sm-4',
              image_css: 'max-w-19rem',
              image_url: 'openapihub.png'
            }
          ]
        }
      ]
    }
  },
  computed: {
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-inline-video-player').each(function() {
      new HSVideoPlayer($(this)).init()
    })
    // this.loadScriptRecapSubscribeForm('https://js.hsforms.net/forms/v2.js', () => {
    //   const secondScript = document.createElement('script')
    //   secondScript.innerHTML = `
    //     hbspt.forms.create({
    //       portalId: "7091000",
    //       formId: "4daac438-a701-4a30-89de-fefdd7a7306f"
    //     });
    //   `
    //   this.$refs.recapSubscribeForm.appendChild(secondScript)
    // })
    // window.addEventListener('message', event => {
    //   if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
    //     this.isSubmitted = true
    //   }
    // })
    $('.js-fancybox').each(function() {
      $.HSCore.components.HSFancyBox.init($(this))
    })
  },
  methods: {
    // loadScriptRecapSubscribeForm(url, callback) {
    //   var script = document.createElement('script')
    //   script.type = 'text/javascript'
    //   script.src = url
    //   script.onreadystatechange = callback
    //   script.onload = callback
    //   this.$refs.recapSubscribeForm.appendChild(script)
    // }
    clearForm() {
      this.$refs.mcEmbeddedSubscribeForm.reset()
    }
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'Apidays Hong Kong 2023 | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/events/apidays-hongkong-2023' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'Apidays Hong Kong 2023 | beNovelty' },
        { property: 'og:description', content: 'Late August, 2023 - The biggest API-first conference in Hong Kong | Apidays Hong Kong 2023 is going to bring together leading API experts both local and international to share their seasonal insights and lessons learnt in driving the team and business under the “new normal”.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/apidays-hongkong-2023.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/events/apidays-hongkong-2023' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/fancybox/dist/jquery.fancybox.min.css");
</style>

